import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LockIcon from "./svgComponents/Lock"

import * as styles from "./TimelineBar.module.css"
import { graphql, useStaticQuery } from "gatsby"

const TimelineStepWrapper = props => (
  <div className={styles.timelineStepWrapper}>
    <div className={styles.timelineStep}>
      {props.children}
    </div>
  </div>
)

const TimelineBar = props => {
  // Create a custom CSS property to calculate the width of the progress bar
  // according to the latest unlocked reward.
  let cssProperty = {}
  cssProperty["--last-unlocked-reward"] = props.lastUnlockedReward.toString()

  const data = useStaticQuery(graphql`
    {
      strapiGlobal {
        decoration: timelineDecoration {
          localFile {
            childImageSharp {
              gatsbyImageData (
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        line: timelineLine {
          localFile {
            publicURL
          }
        }
      }
    }
  `)

  const lineBackgroundImage = {
    backgroundImage: `url(${data.strapiGlobal.line.localFile.publicURL})`
  }

  return (
    <div className={styles.timeline}>
      <div className={styles.timelineBar}>
        <GatsbyImage
          className={styles.timelineDecoration}
          image={getImage(data.strapiGlobal.decoration.localFile)}
          alt="Timeline decoration"
        />
        <div className={styles.timelineBarMiddle}>
          <div style={lineBackgroundImage} className={styles.timelineBarMiddleLine} />
          <div style={lineBackgroundImage} className={styles.timelineBarMiddleLine} />
          <div className={styles.progressBar} style={cssProperty} />
        </div>
        <GatsbyImage
          className={styles.timelineDecoration}
          image={getImage(data.strapiGlobal.decoration.localFile)}
          alt="Timeline decoration"
        />
      </div>
      <div className={styles.timelineSteps}>
        {props.unlockedEvents.map((event, index) => (
          <TimelineStepWrapper key={event.id}>
              <span>{index + 1}</span>
          </TimelineStepWrapper>
        ))}
        {props.lockedEvents.map(event => (
          <TimelineStepWrapper key={event.id}>
            <div className={styles.lockIcon}>
              <LockIcon />
            </div>
          </TimelineStepWrapper>
        ))}
      </div>
    </div>
  )
}

export default TimelineBar
