import React from "react"

function LockIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.889 32">
      <path d="M24.889 30.584v-14.5a1.416 1.416 0 00-1.416-1.416h-1.354v-4.136C22.119 4.528 17.96 0 12.444 0S2.77 4.528 2.77 10.532v4.138H1.416A1.416 1.416 0 000 16.086v14.5A1.416 1.416 0 001.416 32h22.057a1.416 1.416 0 001.416-1.416zm-11.014-6.777a.347.347 0 00-.184.3v2.941a1.294 1.294 0 01-1.179 1.315 1.246 1.246 0 01-1.312-1.244v-3.012a.346.346 0 00-.183-.3 2.939 2.939 0 112.86 0zm4.333-9.137H6.681v-4.138c0-3.9 2.37-6.621 5.763-6.621s5.763 2.723 5.763 6.621z" />
    </svg>
  )
}

export default LockIcon
