import React from "react"

import * as styles from "./Button.module.css"

const Button = props => (
  <a className={styles.button} href={props.url}>
    <div className={styles.buttonInner}>
      <span
        className={styles.buttonLabel}
        dangerouslySetInnerHTML={{ __html: props.label }}
      />
    </div>
  </a>
)

export default Button
