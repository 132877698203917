import { useStaticQuery, graphql } from "gatsby"
import React from "react"

import * as styles from "./BackgroundParallax.module.css"

const getElementStyle = (cardsNumber, bgUrl) => {
  return {
    '--cards-number': cardsNumber,
    'backgroundImage': `url(${bgUrl})`
  }
}

const BackgroundParallax = ({totalElements}) => {

  const {bgBase, bgBack, bgDeep} = useStaticQuery(
    graphql`
      {
        strapiGlobal {
          bgBase: backgroundBase {
            localFile {
              publicURL
            }
          }
          bgBack: backgroundBack {
            localFile {
              publicURL
            }
          }
          bgDeep: backgroundDeep {
            localFile {
              publicURL
            }
          }
        }
      }
    `
  ).strapiGlobal

  return (
  <>
    <div
      style={getElementStyle(totalElements, bgDeep.localFile.publicURL)} 
      className={`${styles.backgroundParallaxLayer} ${styles.backgroundParallaxLayerDeep}`}
    ></div>
    <div
      style={getElementStyle(totalElements, bgBack.localFile.publicURL)} 
      className={`${styles.backgroundParallaxLayer} ${styles.backgroundParallaxLayerBack}`}
    ></div>
    <div
      style={getElementStyle(totalElements, bgBase.localFile.publicURL)} 
      className={`${styles.backgroundParallaxLayer} ${styles.backgroundParallaxLayerBase}`}
    ></div>
  </>
)}

export default BackgroundParallax
