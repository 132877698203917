import React from "react"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"

import * as styles from "./CardLocked.module.css"

let rewardPaletteClass

const CardLocked = props => {
  if (props.rewardTypeName === "orange") {
    rewardPaletteClass = styles.orange
  } else if (props.rewardTypeName === "blue") {
    rewardPaletteClass = styles.blue
  } else if (props.rewardTypeName === "purple") {
    rewardPaletteClass = styles.purple
  }

  return (
    <div
      className={`${styles.cardLocked} ${rewardPaletteClass}`}
      id={props.id}
      style={{ backgroundImage: `url(${getSrc(props.backgroundImageData)})` }}
    >
      <div className={styles.cardLockedChest}>
        <GatsbyImage image={props.chest} alt="Chest" />
      </div>
      <div className={styles.cardUnlockedDateWrapper}>
        <GatsbyImage image={props.boxDate} alt={`${props.title} title box`} />
        <span className={`${styles.cardLockedDate} ${rewardPaletteClass}`}>
          {props.date}
        </span>
      </div>
    </div>
  )
}

export default CardLocked
