import React from "react"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import Button from "../Button/Button"
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm'

import * as styles from "./CardUnlocked.module.css"

let titlePaletteClass

const CardUnlocked = React.forwardRef((props, ref) => {
  if (props.rewardTypeName === "orange") {
    titlePaletteClass = styles.orange
  } else if (props.rewardTypeName === "blue") {
    titlePaletteClass = styles.blue
  } else if (props.rewardTypeName === "purple") {
    titlePaletteClass = styles.purple
  }

  return (
    <div ref={ref} className={styles.cardUnlocked} id={props.id}>
      <div className={styles.cardUnlockedTitleWrapper}>
        <GatsbyImage image={props.boxTitle} alt={`${props.title} title box`} />
        <span className={`${styles.cardUnlockedTitle} ${titlePaletteClass}`}>
          {props.title}
        </span>
      </div>
      <div
        className={styles.cardUnlockedMain}
        style={{ backgroundImage: `url(${getSrc(props.backgroundImageData)})` }}
      >
        <div>
          <GatsbyImage image={props.reward} alt={props.title} />
          <p className={styles.cardUnlockedDescription}>
            <ReactMarkdown plugins={[remarkGfm]}>{props.description}</ReactMarkdown>
            <br/>
            <span
              style={{fontWeight: "900", fontSize: "1.13rem"}}
            >
              <ReactMarkdown plugins={[remarkGfm]}>{props.claim}</ReactMarkdown>
            </span>
          </p>

        </div>
        <Button label={props.buttonLabel} url={props.buttonURL} />
      </div>
    </div>
  )
})

export default CardUnlocked
