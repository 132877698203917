import React, { useRef, useLayoutEffect } from "react"
import { getImage } from "gatsby-plugin-image"
import { useHorizontalScroll } from "../../hooks"
import { useViewportScroll, animate } from "framer-motion"

import BackgroundParallax from "../BackgroundParallax/BackgroundParallax"
import CardUnlocked from "../CardUnlocked/CardUnlocked"
import CardLocked from "../CardLocked/CardLocked"
import TimelineBar from "../TimelineBar/TimelineBar"
import * as styles from "./EventsWrapper.module.css"

const EventsWrapper = props => {
  const ref = useHorizontalScroll()
  const lastUnlockedRef = useRef()

  const { scrollX } = useViewportScroll()

  useLayoutEffect(() => {
    const el = ref.current
    const lastEl = lastUnlockedRef.current
    // There is no reason to proceed when any of the refs is null
    if (!el || !lastEl) return;

    scrollX.onChange(v => el.scrollTo({ left: v }))

    const boundingRect = lastEl.getBoundingClientRect()
    const toPosition =
      boundingRect.left - window.innerWidth / 2 + boundingRect.width / 2

    console.log("Starting INIT animation")
    let controls = animate(scrollX, toPosition, {
      type: "spring",
      delay: 1,
      duration: 4,
    })

    // This callback executed during the onload event will
    // fix the animation to the correct position
    const onLoadAnimationCorrection = () => {
      // first stop the previous animation
      controls.stop();

      // get the bounding rect again, now with the correct measure
      // and compute the new poition
      const boundingRect = lastEl.getBoundingClientRect()
      const toPosition =
        boundingRect.left - window.innerWidth / 2 + boundingRect.width / 2

      // last thing is to restart the animation and substitute the controls
      // binding
      console.log("Starting FIX animation")
      controls = animate(scrollX, toPosition, {
        type: "spring",
        delay: 1,
        duration: 4,
      })
    }

    window.addEventListener("load", onLoadAnimationCorrection)

    const cleanup = () => {
      controls.stop()
      window.removeEventListener("load", onLoadAnimationCorrection)
    }

    return cleanup
  })

  return (
    <div className={styles.eventsWrapper} ref={ref}>
      <BackgroundParallax totalElements={props.totalElements}/>
      <div className={styles.eventsTimeline} style={{'--cards-number': props.totalElements}}>
        <div className={styles.cards}>
        {props.unlockedEvents.map((event, index) => (
            <div key={event.id}>
              <CardUnlocked
                id={event.id}
                key={event.id}
                rewardTypeName={event.rewardType.typeName}
                boxTitle={getImage(event.rewardType.boxTitle.localFile)}
                title={event.title}
                reward={getImage(event.reward.localFile)}
                description={event.description}
                claim={event.claim}
                buttonLabel={event.buttonLabel}
                buttonURL={event.buttonURL}
                backgroundImageData={getImage(event.backgroundImage.localFile)}
                ref={
                  index === props.highlightedElement - 1
                    ? lastUnlockedRef
                    : undefined
                }
              />
            </div>
          ))}
          {props.lockedEvents.map(event => (
            <div key={event.id}>
                <CardLocked
                  id={event.id}
                  key={event.id}
                  rewardTypeName={event.rewardType.typeName}
                  boxDate={getImage(event.rewardType.boxDate.localFile)}
                  date={event.date}
                  chest={getImage(event.rewardType.chest.localFile)}
                  backgroundImageData={getImage(event.backgroundImage.localFile)}
                />
            </div>
          ))}
        </div>
        <TimelineBar
          unlockedEvents={props.unlockedEvents}
          lockedEvents={props.lockedEvents}
          lastUnlockedReward={props.highlightedElement}
        />
      </div>
    </div>
  )
}

export default EventsWrapper
