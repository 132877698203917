import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import EventsWrapper from "../components/EventsWrapper/EventsWrapper"
import Seo from "../components/seo"

export default function Home({ data }) {

  return (
    <Layout>
      <Seo title="" />
      <EventsWrapper
        lockedEvents={data.lockedEvents.nodes}
        unlockedEvents={data.unlockedEvents.nodes}
        totalElements={data.lockedEvents.totalCount + data.unlockedEvents.totalCount}
        highlightedElement={data.unlockedEvents.totalCount}
      />
    </Layout>
  )
}

// Find a way to optimize background images: use compressed formats such as
// Avif and WebP, and improve their rendering process by using a blurred placeholder.
// Since we have a lot of images we should find a way to adopt these techniques
// that would significantly speed up the rendering process.
// Take a look at https://www.gatsbyjs.com/plugins/gatsby-background-image/.
export const query = graphql`
  query {
    lockedEvents: allStrapiEvent(filter: {locked: {eq: true}}, sort: {order: ASC, fields: date}) {
      totalCount
      nodes {
        id
        rewardType {
          typeName
          boxDate {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
          chest {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
        date
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    unlockedEvents: allStrapiEvent(filter: {locked: {eq: false}}, sort: {order: ASC, fields: date}) {
      totalCount
      nodes {
        id
        rewardType {
          typeName
          boxTitle {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
        title
        reward {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        description
        claim
        buttonLabel
        buttonURL
      }
    }
  }
`
